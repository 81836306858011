import React, { useContext } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";
import withTheme from "composers/withTheme";

import Plan from "components/ShopView/Plan";
import Panel from "components/AccountView/Input/Panel";
import Button from "components/Input/Button";

import { AccountContext } from "context/AccountContext";

const Info = compose(withTheme)(styled.div`
  width: 100%;
  ${({ responsiveSize }) =>
    responsiveSize === "phone" && "margin-bottom: 22px;"}
`);

const ManageSubscriptionView = (props) => {
  const { responsiveSize } = props;

  const { subscription } = useContext(AccountContext);

  const handleUpgrade = (e) => {
    props.push("/change");
  };

  const handleBuy = (e) => {
    props.push("/bundles");
  };

  return (
    <Panel invisible={responsiveSize === "phone" ? 1 : 0} style={props.style}>
      <div>
        <h3>Current Plan</h3>
      </div>
      <Info>
        {subscription ? (
          <React.Fragment>
            <Plan
              bundle={subscription}
              current={true}
              // variant="inverted"
              buttonOverride={
                <table style={{ marginLeft: "auto" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "right" }}>Payment:</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {subscription.status}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "right" }}>Expires:</td>
                      <td>
                        {new Date(subscription.expiry_date).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            />
            <Button
              onClick={handleUpgrade}
              variant="contained"
              style={{ marginLeft: "auto", display: "flex" }}
            >
              Change Plan
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>No plan found</div>
            <Button onClick={handleBuy} variant="contained">
              Shop
            </Button>
          </React.Fragment>
        )}
      </Info>
    </Panel>
  );
};

export default compose(
  connect(null, (dispatch) => ({
    push: (url) => dispatch(push(url)),
  })),
  withTheme
)(ManageSubscriptionView);
