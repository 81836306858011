import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "recompose";
import styled from "styled-components";

import withTheme from "composers/withTheme";
import BackArrow from "components/LoginView/Input/BackArrow";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { goBack } from "connected-react-router";
import { connect } from "react-redux";

const Container = compose(withTheme)(styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0
    ${({ responsiveSize }) => (responsiveSize === "phone" ? "0px" : "20px")};
  border-radius: inherit;
`);

class PasswordRecovery extends React.Component {
  render() {
    const { path } = this.props.match;

    return (
      <Container>
        <BackArrow
          style={{ alignSelf: "flex-start" }}
          onClick={() => this.props.goBack()}
        />
        <Switch>
          <Route
            path={`${path}/1`}
            render={(routerProps) => <Step1 basePath={path} {...routerProps} />}
          />
          <Route
            path={`${path}/2`}
            render={(routerProps) => <Step2 basePath={path} {...routerProps} />}
          />
          <Route
            path={`${path}/3`}
            render={(routerProps) => <Step3 basePath={path} {...routerProps} />}
          />
          <Route render={() => <Redirect to={`${path}/1`} />} />
        </Switch>
      </Container>
    );
  }
}

export default connect(null, (dispatch) => ({
  goBack: () => dispatch(goBack()),
}))(PasswordRecovery);
