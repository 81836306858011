import React, { useEffect, useState } from "react";

export const DebugContext = React.createContext();

export const DebugProvider = (props) => {
  const [debug, setDebug] = useState(false);

  useEffect(() => {
    setDebug(props.debug);
  }, [props.debug]);

  return (
    <DebugContext.Provider value={debug}>
      {props.children}
    </DebugContext.Provider>
  );
};
