import React from "react";
import styled from "styled-components";

const Logo = styled.img`
  max-width: 325px;
  min-width: 250px;
  user-select: none;
`;

export default class extends React.Component {
  render() {
    const { src, rotated, ..._props } = this.props;

    return (
      <Logo
        {..._props}
        src={
          src ||
          (rotated
            ? require("assets/general/TusitawiRotated.svg")
            : require("assets/general/TusitawiNotRotated.svg"))
        }
      />
    );
  }
}
