import { compose } from "recompose";
import styled from "styled-components";
import withTheme from "composers/withTheme";

const ScrollableArea = compose(withTheme)(styled.div`
  min-height: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "60%" : "100%"};
  width: 100%;
  padding: 24px 0;

  ${({ centered }) =>
    centered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`);

export default ScrollableArea;
