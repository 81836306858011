import React from "react";
import { compose } from "recompose";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import withTheme from "composers/withTheme";

import uniqueId from "lodash/uniqueId";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

class TextInput extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isPassword: false,
      passwordIsVisible: false,
    };
  }

  componentDidMount() {
    this.uuid = uniqueId();
    if (this.props.type === "password") {
      this.setState({
        isPassword: true,
      });
    }
  }

  onInputChange = (event) => {
    this.props.onChange(event);
  };

  togglePasswordVisibility = () => {
    const passwordVisibility = this.state.passwordIsVisible;

    this.setState({
      passwordIsVisible: !passwordVisibility,
    });
  };

  render() {
    const {
      autoComplete,
      disabled,
      error,
      errorMessage,
      icon,
      label,
      max,
      min,
      name,
      placeholder,
      responsiveSize,
      type,
      value,
    } = this.props;

    return (
      <FormControl
        variant="outlined"
        disabled={disabled}
        error={!!error}
        style={{ alignSelf: "center" }}
        margin={responsiveSize === "phone" ? "dense" : "none"}
        fullWidth
      >
        {label && <InputLabel htmlFor={this.uuid}>{label}</InputLabel>}
        <OutlinedInput
          fullWidth
          type={
            this.state.isPassword && this.state.passwordIsVisible
              ? "text"
              : this.state.isPassword
              ? "password"
              : type
          }
          placeholder={placeholder}
          startAdornment={
            icon && <InputAdornment position="start">{icon}</InputAdornment>
          }
          endAdornment={
            this.state.isPassword && (
              <InputAdornment position="end">
                <IconButton
                  onClick={this.togglePasswordVisibility}
                  aria-label="Toggle password visibillity"
                >
                  {this.state.passwordIsVisible ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }
          inputProps={{ min: min, max: max, autoComplete: autoComplete }}
          id={this.uuid}
          name={name}
          label={label}
          value={value}
          onChange={this.onInputChange}
        />
        {error && errorMessage && (
          <FormHelperText id={`${this.uuid}-error-text`}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default compose(withTheme)(TextInput);
