import React, { useContext } from "react";
import styled from "styled-components";

import { H3 } from "components/LoginView/Headings";
import Plan from "components/ShopView/Plan";
import { AccountContext } from "context/AccountContext";
import { FormatKenyanShillings } from "helpers/PriceFormatter";

const PriceTable = styled.table`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  border-collapse: collapse;

  & tr:last-child {
    border-top: 1px solid grey;
  }
`;

const OrderOverview = (props) => {
  const { subscription } = useContext(AccountContext) || {};

  const { bundle, upgrade, highlightPlan = true } = props;
  const { price, credit, adjusted_price, next_billing_date } = bundle;

  return (
    <React.Fragment>
      {upgrade && (
        <React.Fragment>
          <H3 style={{ textAlign: "left" }}>Your current plan</H3>
          <Plan bundle={subscription} upgrade={true} current={true} />
        </React.Fragment>
      )}
      <H3 style={{ textAlign: "left" }}>Your new plan</H3>
      <Plan
        bundle={bundle}
        hideButton={true}
        upgrade={true}
        disabled={true}
        variant={highlightPlan ? "inverted" : "default"}
      />
      {upgrade && (
        <React.Fragment>
          <H3 style={{ textAlign: "left" }}>What happens next</H3>
          <ul>
            <li>Your new plan starts today.</li>
            <li>
              Starting {next_billing_date}, you will be charged{" "}
              {FormatKenyanShillings(price)}.
            </li>
            <li>
              We will credit the remainder of your current plan to your new
              plan.
            </li>
          </ul>
        </React.Fragment>
      )}
      <H3 style={{ textAlign: "left" }}>Payment details</H3>
      <PriceTable>
        <tbody>
          <tr>
            <td>Your new plan</td>
            <td style={{ textAlign: "right" }}>
              {FormatKenyanShillings(price)}
            </td>
          </tr>
          {credit && (
            <tr style={{ color: "green" }}>
              <td>Your remaining credit</td>
              <td style={{ textAlign: "right" }}>
                &ndash;{FormatKenyanShillings(credit)}
              </td>
            </tr>
          )}
          <tr>
            <td>TOTAL</td>
            <td style={{ textAlign: "right" }}>
              {FormatKenyanShillings(
                typeof adjusted_price === "undefined" ? price : adjusted_price
              )}
            </td>
          </tr>
        </tbody>
      </PriceTable>
    </React.Fragment>
  );
};

export default OrderOverview;
