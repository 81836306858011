import React, { useEffect, useState } from "react";

export const AppContext = React.createContext();

export const AppProvider = (props) => {
  const [appStyle, setAppStyle] = useState({});
  const [accountRequirements, setAccountRequirements] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!!props.appStyle || !!props.accountRequirements) {
      setAppStyle(props.appStyle);
      setAccountRequirements(props.accountRequirements);
      setLoaded(true);
    }
  }, [props.appStyle, props.accountRequirements]);

  return (
    <AppContext.Provider value={{ appStyle, accountRequirements, loaded }}>
      {props.children}
    </AppContext.Provider>
  );
};
