import FS from "./FetchService";

export default class StudentService {
  static async getAll() {
    return await FS.get("/students");
  }

  static async getOne(student_id) {
    return await FS.get(`/students/${student_id}`);
  }

  static async getAvailableGrades() {
    return await FS.get("/available_grades");
  }

  static async getLoginUrl(student_id) {
    return await FS.get(`/students/${student_id}/login_url`);
  }

  static async create({
    first_name,
    last_name,
    avatar,
    gender,
    age,
    school,
    grade,
    county,
  }) {
    const form = new FormData();
    form.append("first_name", first_name || "");
    form.append("last_name", last_name || "");
    form.append("avatar", avatar || "");
    form.append("gender", gender || "");
    form.append("age", age || "");
    form.append("school", school || "");
    form.append("grade", grade || "");
    form.append("county", county || "");

    return await FS.post("/students", form);
  }

  static async update({
    student_id,
    first_name,
    avatar,
    last_name,
    gender,
    age,
    school,
    grade,
    county,
  }) {
    const form = new FormData();
    form.append("first_name", first_name || "");
    form.append("last_name", last_name || "");
    form.append("avatar", avatar || "");
    form.append("gender", gender || "");
    form.append("age", age || "");
    form.append("school", school || "");
    form.append("grade", grade || "");
    form.append("county", county || "");

    return await FS.patch(`/students/${student_id}`, form);
  }

  static async delete(student_id) {
    return await FS.delete(`/students/${student_id}`);
  }
}
