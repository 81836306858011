import React from "react";
import { compose } from "recompose";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";

import _Avatar from "components/AccountView/Student/Avatar";
import Button from "components/Input/Button";
import withTheme from "composers/withTheme";

const Avatar = styled(_Avatar)`
  width: 30vw;
  max-width: 150px;
  max-height: 150px;
  margin: 10px;
  flex-direction: row;

  background-color: #104f66;
  background-repeat: "no-repeat";
  background-size: "contain";
  background-position: "center";

  transition: background-color 0.2s ease, border 0.2s ease, box-shadow 0.2s ease;

  :hover,
  :focus {
    transform: scale(1.05);
    transition: transform 0.2s;
    cursor: pointer;
  }

  :focus {
    border: 4px solid #000000;
    outline-width: 0;
  }
`;

// Useful wrapper component
const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

// This is so responsiveSize doesn't end up throwing a React warning
// for non-standard props
const WrappedGrid = ({ responsiveSize, ...props }) => {
  return <Grid {...props} />;
};

// The sticky header where the Save/Cancel buttons are
const ButtonHeader = compose(withTheme)(styled(WrappedGrid)`
  position: sticky;
  top: ${({ responsiveSize }) =>
    responsiveSize === "desktop" ? "62px" : "55px"};
  height: 12.5vh;
  padding-right: ${({ responsiveSize }) =>
    responsiveSize !== "phone" && "25px"};

  display: flex;
  justify-self: center;

  justify-content: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "space-around" : "flex-end"};
  align-items: center;

  background-color: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "#ffffff" : "#fafafa"};
  border-bottom: "1px solid rgba(0, 0, 0, 0.2)";
  box-shadow: "0px 5px 5px -5px rgba(0, 0, 0, 0.5)";
  z-index: 3;
`);

// Used for the interactive parts of the color picker
const Path = styled.path`
  opacity: 0.85;
  outline-width: 0px;
  transition: stroke 0.2s ease, stroke-width 0.2s ease;

  :focus,
  :hover {
    opacity: 1;
    stroke: #000;
    stroke-width: 1px;
  }
`;

const ColorOption = (props) => {
  return (
    <Path
      fill={props.svgFill}
      onClick={() => {
        props.colorChooser(props.svgFill);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          props.colorChooser(props.svgFill);
        }
      }}
      tabIndex={0}
      aria-label={`Choose ${props.colorName} as a Background Color`}
      style={{
        transform: props.translation,
        filter:
          props.currentColor !== props.svgFill &&
          "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25)",
      }}
      strokeWidth={props.currentColor === props.svgFill ? 2 : 0}
      d={props.svgD}
    />
  );
};

const AvatarOption = (props) => {
  return (
    <Avatar
      color={props.currentColor}
      icon={props.option.icon}
      border={
        props.currentIcon === props.option.icon &&
        props.currentColor !== "#104f66"
          ? "5px solid #000000"
          : props.currentIcon === props.option.icon && "5px solid #ffffff"
      }
      boxShadow={
        props.currentIcon !== props.option.icon
          ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
          : "0px 0px 4px 5px rgba(0, 0, 0, 0.25)"
      }
      opacity={props.currentIcon !== props.option.icon && "75%"}
      onClick={() => {
        props.clickHandler();
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          props.enterHandler();
        }
      }}
      tabIndex={0}
    ></Avatar>
  );
};

class AvatarPicker extends React.Component {
  constructor(props) {
    super();
    this.state = {
      // Passed from the NewOrEditStudentView as props (if applicable)
      icon: props.icon,
      color: props.color,

      // These will be computed in a bit
      availableAvatars: null,
    };
  }

  // This gets some dummy data for the time-being
  componentDidMount() {
    // A JSON file with the filenames of all the avatars
    fetch("/custom/login/avatars/options.json")
      .then((response) => response.json()) // Parsing the response
      .then((data) => {
        // Adds the full URL to each icon's value
        let filteredAvatars = data.avatars.map((optionsArray) => {
          return optionsArray.map((option) => {
            return { icon: `/custom/login/avatars/${option.icon}` };
          });
        });

        filteredAvatars[0].unshift({ icon: "null" });

        this.setState({
          availableAvatars: filteredAvatars,
        });
      });
  }

  // Custom interactivity functions
  chooseAvatar = (avatarData) => {
    this.setState({
      icon: avatarData.icon,
    });
  };

  chooseColor = (chosenColor) => {
    this.setState({
      color: chosenColor,
    });
  };

  render() {
    return (
      <div style={{ maxWidth: "800px" }}>
        <ButtonHeader container spacing={2}>
          <Button
            style={{ margin: "40px" }}
            onClick={() => {
              this.props.onClose(this.state);
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              this.props.onClose();
            }}
          >
            Cancel
          </Button>
        </ButtonHeader>

        <h3 style={{ textAlign: "center" }}> Select Background Color:</h3>

        <Container>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 71.1 71.3"
            className="l4h--logo"
            stroke="#000000"
            strokeWidth={0}
            style={{
              height: "200px",
              overflow: "visible",
              marginTop: "2vh",
              marginBottom: "2vh",
            }}
          >
            <ColorOption
              colorName="Blue"
              svgFill="#104f66"
              currentColor={this.state.color}
              svgD="M30.5 47.6c3-2.2 6-2.4 10.2 0l18.9 14.3c-3.8 3.3-7 5.3-9.4 6l-5.5-4.8v7a34.2 34.2 0 01-18 0v-6.8L21 68a30.5 30.5 0 01-9.3-5.8z"
              translation="translateY(5%)"
              colorChooser={this.chooseColor}
            />

            <ColorOption
              colorName="Green"
              svgFill="#168d44"
              currentColor={this.state.color}
              svgD="M47.3 40.8c-2.1-3-2.3-6 0-10.2l14.4-19c3.3 3.8 5.3 7 6 9.4l-4.9 5.6 7-.1a34.2 34.2 0 010 18.1H63l4.8 5.6a30.5 30.5 0 01-5.8 9.2z"
              translation="translateX(5%)"
              colorChooser={this.chooseColor}
            />

            <ColorOption
              colorName="Red"
              svgFill="#ed174b"
              currentColor={this.state.color}
              svgD="M40.9 23.8c-3 2.1-6 2.3-10.2 0L11.8 9.4c3.8-3.3 7-5.3 9.4-6l5.5 4.9v-7a34.2 34.2 0 0118 0V8l5.6-4.8C54 4.8 56.6 6.5 59.6 9z"
              translation="translateY(-5%)"
              colorChooser={this.chooseColor}
            />

            <ColorOption
              colorName="Yellow"
              svgFill="#fcb034"
              currentColor={this.state.color}
              svgD="M23.8 30.6c2.1 3 2.3 6 0 10.1l-14.4 19c-3.3-3.8-5.3-7-6-9.4l4.9-5.5h-7a34.2 34.2 0 010-18.1H8L3.3 21C4.8 17.5 6.5 15 9 12z"
              translation="translateX(-5%)"
              colorChooser={this.chooseColor}
            />

            <path
              className="circle"
              fill={this.state.color}
              d="M35.6 26.7a9 9 0 100 18 9 9 0 000-18z"
            />
          </svg>
        </Container>

        <h3 style={{ textAlign: "center" }}>Select Profile Photo:</h3>

        {this.state.availableAvatars &&
          this.state.availableAvatars.map((optionArray, index) => {
            return (
              <Container key={index} style={{ marginTop: "15%" }}>
                {optionArray.map((option, index) => {
                  return (
                    <AvatarOption
                      key={index}
                      option={option}
                      clickHandler={() => {
                        this.chooseAvatar(option);
                      }}
                      enterHandler={() => {
                        this.chooseAvatar(option);
                      }}
                      currentIcon={this.state.icon}
                      currentColor={this.state.color}
                    />
                  );
                })}
              </Container>
            );
          })}
      </div>
    );
  }
}

export default AvatarPicker;
