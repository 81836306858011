import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";
import Panel from "components/AccountView/Input/Panel";
import _Avatar from "components/AccountView/Student/Avatar";
import Button from "components/Input/Button";
import LoadingContainer from "components/Loading/LoadingContainer";
import withTheme from "composers/withTheme";
import { push } from "connected-react-router";
import StudentService from "services/StudentService";
import { H2 } from "components/AccountView/Headings";

import { AccountContext } from "context/AccountContext";
import { Warning } from "@material-ui/icons";
import { MaybePluralize } from "helpers/Pluralizer";

const StudentPanel = compose(withTheme)(styled(Panel)`
  position: relative;
  height: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "85px" : "105px"};
  width: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "100%" : "680px"};
  min-width: 350px;
  min-height: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "50%" : "100%"};
  padding-left: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "80px" : "100px"};
  padding-right: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "30px" : "40px"};

  display: flex;
  justify-content: space-between;
  align-items: center;
`);

const Avatar = compose(withTheme)(styled(_Avatar)`
  position: absolute;
  width: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "52px" : "57px"};
  left: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "15px" : "24px"};
  top: 50%;
  transform: translateY(-50%);
  background-color: #fbb74d;
`);

const StudentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StudentInfo = styled.div`
  margin-bottom: 3px;
`;
const StudentButtonGroup = styled.div`
  display: flex;
`;
const Student = (props) => {
  const { first_name, last_name, grade, avatar } = props.student;

  return (
    <React.Fragment>
      <Avatar jsonData={avatar} backgroundSize="90%"></Avatar>
      <StudentInfoContainer>
        <StudentInfo style={{ fontWeight: "bold" }}>
          {first_name} {last_name}
        </StudentInfo>
        <StudentInfo>{grade}</StudentInfo>
      </StudentInfoContainer>
      <StudentButtonGroup>
        <Button onClick={props.onEdit}>Edit</Button>
        {props.removable && (
          <Button
            color="secondary"
            onClick={props.onRemove}
            style={{ marginLeft: "5px" }}
          >
            Delete
          </Button>
        )}
      </StudentButtonGroup>
    </React.Fragment>
  );
};

const ManageStudents = (props) => {
  const { subscription } = useContext(AccountContext);

  const [state, setState] = useState({
    students: [],
    loaded: false,
  });

  const refresh = async () => {
    const students = await StudentService.getAll();
    setState((orig) => ({ ...orig, students: students, loaded: true }));
  };

  const mount = () => {
    refresh();

    return () => {};
  };
  useEffect(mount, []);

  const handleRemove = async (student) => {
    if (window.confirm("Are you sure you want to remove this student?")) {
      try {
        if ((await StudentService.delete(student.student_id)) === 1) {
          refresh();
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      }
    }
  };

  const { path } = props.match;
  const { responsiveSize } = props;

  let studentDifference = 0;
  let tooManyStudents = false;
  if (subscription?.students && state.students) {
    studentDifference = state.students.length - subscription.students;
    tooManyStudents = studentDifference > 0;
    studentDifference = Math.abs(studentDifference);
  }

  return (
    <Grid style={{ borderRadius: "inherit", width: "680px", maxWidth: "100%" }}>
      {responsiveSize !== "phone" && <H2>Manage Students</H2>}
      {state.loaded ? (
        state.students.length ? (
          <React.Fragment>
            {tooManyStudents && (
              <React.Fragment>
                <div
                  style={{
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "5px",
                  }}
                >
                  <Warning />
                  You have too many students. Please remove {
                    studentDifference
                  }{" "}
                  {MaybePluralize("student", studentDifference)} or upgrade your
                  account.
                </div>
                <StudentPanel style={{ paddingLeft: "32px" }}>
                  <Button onClick={() => props.push(`/change`)}>Upgrade</Button>
                </StudentPanel>
              </React.Fragment>
            )}
            {state.students.map((student) => (
              <StudentPanel key={student.student_id}>
                <Student
                  onEdit={() =>
                    props.push(`${path}/edit/${student.student_id}`)
                  }
                  removable={tooManyStudents}
                  onRemove={() => handleRemove(student)}
                  student={student}
                ></Student>
              </StudentPanel>
            ))}
            {state.students.length < subscription.students && (
              <React.Fragment>
                <div>
                  You can create {studentDifference} more{" "}
                  {MaybePluralize("student", studentDifference)}
                </div>
                <StudentPanel style={{ paddingLeft: "32px" }}>
                  <span>New Student</span>
                  <Button onClick={() => props.push(`${path}/new`)}>
                    Add New
                  </Button>
                </StudentPanel>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <span>
            You do not have any students registered yet. Click{" "}
            <Link to={`${path}/new`}>here</Link> to create a new student.
          </span>
        )
      ) : (
        <LoadingContainer />
      )}
    </Grid>
  );
};

export default compose(
  withTheme,
  connect(null, (dispatch) => ({
    push: (url) => dispatch(push(url)),
  }))
)(ManageStudents);
