import FS from "./FetchService";

export default class LoginService {
  static async login(login, password) {
    const form = new FormData();
    form.append("login", login);
    form.append("password", password);
    const result = await FS.post("/login", form);
    if (!result.errors) {
      sessionStorage.setItem("token", result.access_token);
    }

    return result;
  }

  static async signUp(
    first_name,
    last_name,
    email,
    phone_number,
    county,
    password,
    password_confirmation,
    bundle,
    payment
  ) {
    const data = JSON.stringify({
      first_name,
      last_name,
      email,
      phone_number,
      county,
      password,
      password_confirmation,
      bundle,
      payment,
    });

    const result = await FS.post("/signup", data, {
      header: (h) => h.append("Content-Type", "application/json"),
    });

    if (!result.errors) {
      sessionStorage.setItem("token", result.access_token);
    }

    return result;
  }

  static async me() {
    return await FS.get("/me");
  }

  static async requestPasswordRecovery(login, first_name, last_name) {
    const form = new FormData();
    form.append("login", login);
    form.append("first_name", first_name);
    form.append("last_name", last_name);

    return await FS.post("/request_password_recovery", form);
  }

  static async verifyCode(verification, unique_code) {
    const form = new FormData();
    form.append("verification", verification);
    form.append("unique_code", unique_code);

    return await FS.post("/verify_password_recovery", form);
  }

  static async changePassword(verification, password, password_confirmation) {
    const form = new FormData();
    form.append("verification", verification);
    form.append("password", password);
    form.append("password_confirmation", password_confirmation);

    return await FS.post("/recover_password", form);
  }
}
