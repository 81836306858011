import React, { useState } from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import ExploreBundlesView from "./ExploreBundlesView";
import SelectPayment from "./SelectPayment";
import BackArrow from "components/LoginView/Input/BackArrow";

import { Redirect } from "react-router-dom";
import LoadingContainer from "components/Loading/LoadingContainer";

const ShopView = (props) => {
  const [state, setState] = useState({
    bundle: null,
    payment: null,
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
  };

  const handleBackButton = (e) => {
    const { bundle, payment } = state;
    if (payment) {
      setState({ ...state, payment: null });
    } else if (bundle) {
      setState({ ...state, bundle: null });
    } else {
      props.goBack();
    }
  };

  return (
    <React.Fragment>
      <BackArrow onClick={handleBackButton} />
      {(() => {
        if (state.bundle === null) {
          return (
            <ExploreBundlesView
              name="bundle"
              onSelect={handleChange}
              upgrade={props.upgrade}
            />
          );
        } else if (state.payment === null) {
          return (
            <SelectPayment
              name="payment"
              bundle={state.bundle}
              upgrade={props.upgrade}
              onSubmit={handleChange}
              checkoutText={props.checkoutText}
            />
          );
        } else if (props.after) {
          switch (typeof props.after) {
            case "function":
              props.after(state);
              return <LoadingContainer />;
            default:
              return <props.after {...state} />;
          }
        }

        return <Redirect to="/account/receipt" />;
      })()}
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  goBack: () => dispatch(goBack()),
}))(ShopView);
