import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { withContext, withState } from "recompose";
import { compose } from "redux";
import styled from "styled-components";

import FallbackSwitch from "components/Routing/FallbackSwitch";

import withTheme from "composers/withTheme";
import { string } from "prop-types";

import AccountView from "./views/AccountView/AccountView";
import AccountNavbar from "./views/AccountView/AccountNavbar";
import { LandingViewBody } from "./views/LandingView/LandingView";

import { AppProvider } from "./context/AppContext";
import { AccountProvider } from "./context/AccountContext";
import { DebugProvider } from "./context/DebugContext";
import { NavigationProvider } from "context/NavigationContext";

import LoginService from "services/LoginService";
import AppService from "services/AppService";
import DebugMenu from "components/Debug/DebugMenu";

const RESPONSIVE_SIZES = [
  {
    name: "desktop",
    minWidth: Infinity,
  },
  {
    name: "tablet",
    minWidth: 1200,
  },
  {
    name: "phone",
    minWidth: 800,
  },
];

const BACKGROUND_COLOR = "white";

const Main = styled.div`
  min-height: 100vh;
  background-color: ${BACKGROUND_COLOR};
  display: flex;
  ${({ headerExists, headerHeight }) =>
    headerExists &&
    `
    padding-top: ${headerHeight};
  `}
  align-items: stretch;
`;

const TestingVersion = compose(withTheme)(styled.div`
  position: fixed;
  user-select: none;
  bottom: 0;
  right: 0;
  color: rgba(128, 128, 128, 0.4);
  font-weight: bold;
  text-align: right;
  padding: 1px;
  z-index: 99999;
  pointer-events: none;

  ${({ responsiveSize }) =>
    responsiveSize === "phone" &&
    `
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
  `}
`);

let FeVersion = "";
try {
  FeVersion = require("./version.json");
} catch (e) {
  console.error(e.message);
}

const View = (props) => {
  const { HeaderView, BodyView, responsiveSize, ..._props } = props;
  const headerHeight = responsiveSize !== "desktop" ? "58px" : "75px";

  return (
    <Main headerExists={!!HeaderView} headerHeight={headerHeight}>
      {HeaderView && <HeaderView headerHeight={headerHeight} {..._props} />}
      <BodyView {..._props} />
    </Main>
  );
};

const App = (props) => {
  const [state, setState] = useState({
    windowWidth: 0,
    windowHeight: 0,
    appStyle: null,
    accountRequirements: null,
    account: null,
    apiVersion: "",
    debug: false,
    loaded: false,
  });

  const updateDimensions = () => {
    const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    const windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    let responsiveSize = "";
    for (let i in RESPONSIVE_SIZES) {
      const { name, minWidth } = RESPONSIVE_SIZES[i];
      if (windowWidth <= minWidth) {
        responsiveSize = name;
      }
    }

    setState((orig) => ({ ...orig, windowWidth, windowHeight }));

    if (props.responsiveSize !== responsiveSize) {
      props.setResponsiveSize(responsiveSize);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  });

  const loadAppSettings = async () => {
    const appSettings = await AppService.getAppSettings();
    setState((orig) => ({
      ...orig,
      ...appSettings,
      loaded: true,
    }));
  };

  const mount = () => {
    updateDimensions();
    loadAppSettings();
  };
  useEffect(mount, []);

  const getAccountInformation = async () => {
    const me = await LoginService.me();
    if (me) {
      const { debug, version, ...account } = me;
      setState((orig) => ({ ...orig, account }));
      if (debug) {
        setState((orig) => ({ ...orig, debug }));
      }
      if (version) {
        setState((orig) => ({ ...orig, apiVersion: version }));
      }
    }
  };

  if (!state.loaded) return null;

  return (
    <AppProvider
      appStyle={state.appStyle}
      accountRequirements={state.accountRequirements}
    >
      <DebugProvider debug={state.debug}>
        <AccountProvider account={state.account}>
          <FallbackSwitch>
            <Route
              path="/logout"
              render={() => {
                let expires = new Date(0);
                document.cookie = "Authorization=;path=/;expires=" + expires;
                sessionStorage.clear();
                setTimeout(
                  () => setState((orig) => ({ ...orig, account: null })),
                  0
                );
                if (typeof window.Echo !== "undefined") {
                  window.Echo.disconnect();
                }

                return <Redirect to="/" />;
              }}
            />
            <Route
              path="/"
              render={(routerProps) => {
                const token = sessionStorage.getItem("token");
                if (token && token !== "undefined") {
                  const parsedToken = JSON.parse(atob(token.split(".")[1]));
                  const now = Date.now();
                  const expires = parsedToken.exp * 1000;
                  if (now > expires) {
                    sessionStorage.clear();

                    return <Redirect to="/logout" />;
                  } else {
                    if (!state.account) {
                      getAccountInformation();
                    }
                    return (
                      <NavigationProvider>
                        <View
                          HeaderView={AccountNavbar}
                          BodyView={AccountView}
                          {...props}
                          {...routerProps}
                        />
                        {state.debug && <DebugMenu />}
                      </NavigationProvider>
                    );
                  }
                }

                return (
                  <View
                    BodyView={LandingViewBody}
                    {...props}
                    {...routerProps}
                  />
                );
              }}
            />
          </FallbackSwitch>
          {state.apiVersion && (
            <TestingVersion>
              {FeVersion && <div>FE: {FeVersion}</div>}
              {state.apiVersion && <div>API: {state.apiVersion}</div>}
            </TestingVersion>
          )}
        </AccountProvider>
      </DebugProvider>
    </AppProvider>
  );
};

export default compose(
  withState("responsiveSize", "setResponsiveSize", "desktop"),
  withContext({ responsiveSize: string }, ({ responsiveSize }) => ({
    responsiveSize,
  }))
)(App);
