import React, { useEffect, useState } from "react";

export const AccountContext = React.createContext();

export const AccountProvider = (props) => {
  const [profile, updateProfile] = useState(null);
  const [subscription, updateSubscription] = useState(null);
  const [loaded, updateLoaded] = useState(false);

  useEffect(() => {
    const { subscription: _subscription, profile: _profile } =
      props.account || {};
    updateProfile(_profile);
    updateSubscription(_subscription);
    updateLoaded(!!_profile);
  }, [props.account]);

  return (
    <AccountContext.Provider
      value={{
        profile,
        updateProfile,
        subscription,
        updateSubscription,
        loaded,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
