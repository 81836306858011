import React from "react";

import MaterialButton from "@material-ui/core/Button";

export default function Button(props) {
  const {
    children,
    color,
    disabled,
    icon,
    onClick,
    size,
    style,
    type,
    variant,
  } = props;

  return (
    <MaterialButton
      variant={variant ? variant : "contained"}
      style={style}
      color={color || "primary"}
      size={size ? size : "medium"}
      startIcon={icon}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </MaterialButton>
  );
}
