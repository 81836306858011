import React, { useContext, useEffect, useState } from "react";
import { H2, H3 } from "components/LoginView/Headings";
import { AccountContext } from "context/AccountContext";
import OrderOverview from "../../ShopView/OrderOverview";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import LoadingContainer from "components/Loading/LoadingContainer";
import ShopService from "services/ShopService";

const ReceiptView = (props) => {
  const { subscription } = useContext(AccountContext);
  const [receipt, setReceipt] = useState();

  const loadOrder = async (order_id) => {
    const order_receipt = await ShopService.getReceipt(order_id);
    setReceipt(order_receipt);
  };

  const mount = () => {
    const { order_id } = props.match.params;
    if (!order_id) {
      if (subscription) {
        props.replace(`${props.match.path}${subscription.product_order_id}`);
      }
    } else if (subscription) {
      if (parseInt(subscription.product_order_id) === parseInt(order_id)) {
        setReceipt(subscription);
      } else {
        loadOrder(order_id);
      }
    }

    return () => {};
  };
  useEffect(mount, [subscription, props.match.params.order_id]);

  if (!receipt) return <LoadingContainer />;

  return (
    <React.Fragment>
      {(() => {
        switch (receipt.status) {
          case "pending":
            return (
              <React.Fragment>
                <H2 style={{ fontWeight: "bold", color: "#35B158" }}>
                  Pending Payment
                </H2>
                <H3>Your purchase is being processed</H3>
              </React.Fragment>
            );
          case "complete":
            return (
              <React.Fragment>
                <H2 style={{ fontWeight: "bold", color: "#35B158" }}>
                  <CheckCircleIcon /> Payment Complete
                </H2>
                <H3>
                  Thank you for your purchase!
                  <br />
                  Return to the <Link to="/">home page</Link> to begin learning!
                </H3>
              </React.Fragment>
            );
          default:
        }
      })()}
      <H2 style={{ textAlign: "left", borderBottom: "1px solid black" }}>
        Order Receipt
      </H2>
      <OrderOverview bundle={receipt} highlightPlan={false} />
      <H3>Thank you for learning with Tusitawi!</H3>
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  replace: (url) => dispatch(replace(url)),
}))(ReceiptView);
