import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ButtonGroup, Button } from "@material-ui/core";

const Container = styled.div`
  width: 100%;
`;

const SubscriptionDurationSelector = (props) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  const handleChange = (value) => {
    if (value !== props.value) {
      props.onChange &&
        props.onChange({
          target: {
            name: props.name,
            value,
          },
        });
    }
  };

  return (
    <React.Fragment>
      <Container>
        <ButtonGroup fullWidth disableElevation color="primary">
          {props.options.map((option, i) => (
            <Button
              onClick={() => handleChange(option)}
              key={i}
              variant={option === selected ? "contained" : "outlined"}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  lineHeight: "20px",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {(() => {
                    switch (option) {
                      case "1":
                        return <React.Fragment>Monthly</React.Fragment>;
                      case "12":
                        return <React.Fragment>Yearly</React.Fragment>;
                      default:
                        return (
                          <React.Fragment>{option}&nbsp;Months</React.Fragment>
                        );
                    }
                  })()}
                </span>
                {!!props.bundles[option][0].savings && (
                  <React.Fragment>
                    <span>
                      {props.bundles[option][0].savings}%&nbsp;Discount
                    </span>
                  </React.Fragment>
                )}
              </span>
            </Button>
          ))}
        </ButtonGroup>
      </Container>
    </React.Fragment>
  );
};

export default SubscriptionDurationSelector;
