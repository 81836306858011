import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { compose } from "recompose";

import FullArea from "components/Layout/FullArea";
import FallbackSwitch from "components/Routing/FallbackSwitch";
import withTheme from "composers/withTheme";

import HomeView from "./HomeView/HomeView";
import ManageAccountView from "./ManageAccountView/ManageAccountView";

import Echo from "laravel-echo";
import { AccountContext } from "context/AccountContext";
import ShopView from "views/ShopView/ShopView";
import Panel from "components/AccountView/Input/Panel";
import ScrollableArea from "components/Layout/ScrollableArea";
import ShopService from "services/ShopService";
import { connect } from "react-redux";
import { push } from "connected-react-router";

function initWebsocket(token, profile, updateSubscription) {
  if (!!profile) {
    if (typeof window.io === "undefined") {
      window.io = require("socket.io-client");
    }

    if (typeof window.io !== "undefined") {
      if (typeof window.Echo === "undefined") {
        window.Echo = new Echo({
          broadcaster: "socket.io",
          host: window.location.hostname,
          authEndpoint: "/api/broadcasting/auth",
          auth: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        });
      } else {
        window.Echo.connect();
      }
      if (typeof window.Echo !== "undefined") {
        window.Echo.private("user." + profile.parent_id).listen(
          "ProductOrderUpdated",
          ({ order }) => {
            if (order) {
              updateSubscription && updateSubscription(order);
            }
          }
        );
      }
    }
  }
}

const AccountViewBody = (props) => {
  const { profile, updateSubscription } = useContext(AccountContext);
  const [socketInit, updateSocketInit] = useState(false);
  const [invisible, setInvisible] = useState(false);

  useEffect(() => {
    if (!socketInit && profile && updateSubscription) {
      initWebsocket(
        sessionStorage.getItem("token"),
        profile,
        updateSubscription
      );
      updateSocketInit(true);
    }
  }, [profile, updateSubscription, socketInit]);

  useEffect(() => {
    setInvisible(props.responsiveSize === "phone");
  }, [props.responsiveSize]);

  return (
    <FullArea>
      <FallbackSwitch fallback="/home">
        <Route
          path="/bundles"
          render={(routerProps) => (
            <ScrollableArea centered style={{ fontFamily: "sans-serif" }}>
              <Panel invisible={invisible} style={{ maxWidth: "400px" }}>
                <ShopView
                  {...routerProps}
                  after={async ({ bundle, payment }) => {
                    const order = await ShopService.makePurchase(
                      bundle,
                      payment
                    );
                    setTimeout(() => updateSubscription(order));
                    props.push(`/account/receipt/${order.product_order_id}`);
                  }}
                />
              </Panel>
            </ScrollableArea>
          )}
        />
        <Route
          path="/change"
          render={(routerProps) => (
            <ScrollableArea centered style={{ fontFamily: "sans-serif" }}>
              <Panel invisible={invisible} style={{ maxWidth: "400px" }}>
                <ShopView
                  {...routerProps}
                  upgrade={true}
                  after={async ({ bundle, payment }) => {
                    const order = await ShopService.purchaseUpgrade(
                      bundle,
                      payment
                    );
                    setTimeout(() => updateSubscription(order));
                    props.push(`/account/receipt/${order.product_order_id}`);
                  }}
                />
              </Panel>
            </ScrollableArea>
          )}
        />
        <Route path="/account" component={ManageAccountView} />
        <Route exact path="/home" component={HomeView} />
      </FallbackSwitch>
    </FullArea>
  );
};

export default connect(null, (dispatch) => ({
  push: (url) => dispatch(push(url)),
}))(compose(withTheme)(AccountViewBody));
