import React from "react";
import { compose } from "recompose";
import styled from "styled-components";

import ButtonLink from "components/Input/ButtonLink";
import withTheme from "composers/withTheme";
import { H2 } from "components/AccountView/Headings";

const StatisticWrapper = compose(withTheme)(styled.div`
  display: grid;
  row-gap: 20px;
  grid-auto-rows: minmax(38px, auto);

  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" &&
    `
    justify-content: center;
    justify-items: center;
    row-gap: 13px;
  `}
`);

const Statistic = compose(withTheme)(styled.div`
  display: grid;
  ${({ responsiveSize }) =>
    responsiveSize === "desktop"
      ? `
    grid-template-columns: 35px auto;
  `
      : `
    justify-items: center;
    row-gap: 13px;
  `}

  column-gap: 13px;
  align-items: center;

  color: #44b363;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`);

const StatisticImage = styled.img`
  justify-self: center;
`;

const BookIcon = require("assets/icons/Book.svg");
const GuidedLearningIcon = require("assets/icons/GuidedLearning.svg");
const MapIcon = require("assets/icons/Map.svg");

const LaptopPromo = styled.div`
  position: relative;
  height: 205px;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 116%;
    right: -12px;
    background: ${({ src }) => `transparent url(${src}) no-repeat center`};
    background-size: cover;
  }
`;

LaptopPromo.defaultProps = { src: require("assets/images/laptop_promo.png") };

const PhonePromo = styled.img`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 800;
  height: 264px;
`;

PhonePromo.defaultProps = { src: require("assets/images/phone_promo.png") };

const LearnMore = compose(withTheme)(styled.a`
  ${({ responsiveSize }) =>
    responsiveSize === "desktop"
      ? `
    padding-left: 63px;
  `
      : `
    text-align: center;
  `}
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #767676;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`);

class Promo extends React.Component {
  render() {
    const { responsiveSize } = this.props;

    return (
      <React.Fragment>
        {responsiveSize !== "desktop" && <PhonePromo />}
        <h3>Tusitawi is your child's digital classroom</h3>
        <StatisticWrapper>
          <Statistic>
            <StatisticImage src={GuidedLearningIcon} />
            Guided-learning that supports in-school teaching
          </Statistic>
          <Statistic>
            <StatisticImage src={BookIcon} />
            30,000+ audio, visual and literary resources
          </Statistic>
          <Statistic>
            <StatisticImage src={MapIcon} />
            World class education created by Kenyan and global professionals
          </Statistic>
        </StatisticWrapper>
        {responsiveSize === "desktop" ? (
          <LaptopPromo />
        ) : (
          <StatisticWrapper>
            <H2>Start your free trial!</H2>
            <ButtonLink
              style={{ width: "250px" }}
              yellow
              to="/sign-up"
              handleClick={() => window.scroll({ top: 0 })}
            >
              Sign Up
            </ButtonLink>
          </StatisticWrapper>
        )}
        <LearnMore href="https://ke.tusitawi.com">
          Learn more at ke.tusitawi.com
        </LearnMore>
      </React.Fragment>
    );
  }
}

export default compose(withTheme)(Promo);
