import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Button from "components/Input/Button";
import Dropdown from "components/Input/Dropdown";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import LoadingContainer from "components/Loading/LoadingContainer";
import Form from "components/LoginView/Input/Form";
import { push } from "connected-react-router";
import CountyService from "services/CountyService";
import LoginService from "services/LoginService";

import EmailIcon from "@material-ui/icons/Email";
import LocationIcon from "@material-ui/icons/LocationCity";
import LockIcon from "@material-ui/icons/Lock";
import PhoneIcon from "@material-ui/icons/Phone";
import { H2 } from "components/LoginView/Headings";
import { formatRequiredLabel } from "helpers/RequiredFields";
import { AppContext } from "context/AppContext";
import BackArrow from "components/LoginView/Input/BackArrow";

const Error = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  font-size: 12px;
  width: 100%;
  color: white;
  position: absolute;
  top: 100%;
`;

const SignUp = (props) => {
  const { accountRequirements } = useContext(AppContext);

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: props.payment?.phone || "",
    county: "",
    password: "",
    password_confirmation: "",
    county_options: [],
    error: "",
    prevent_submit: true,
    loaded: [],
    fully_loaded: false,
  });

  const require = ["counties"];
  const setLoaded = (value) => {
    setState((orig) => {
      const loaded = [...orig.loaded, value];
      const newState = { ...orig, loaded };

      if (require.every((item) => loaded.includes(item))) {
        newState.prevent_submit = false;
        newState.fully_loaded = true;
      }

      return newState;
    });
  };

  const loadCounties = async () => {
    try {
      const counties = await CountyService.getCounties();
      const county_options = counties.map(({ county_id, code, name }) => ({
        value: county_id,
        label: `${code} - ${name}`,
      }));
      setState((orig) => ({
        ...orig,
        county_options: county_options,
      }));
      setLoaded("counties");
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const mount = () => {
    loadCounties();

    return () => {};
  };
  useEffect(mount, []);

  const signUp = async () => {
    let redirecting = false;
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));

      try {
        const result = await LoginService.signUp(
          state.first_name,
          state.last_name,
          state.email,
          state.phone_number,
          state.county,
          state.password,
          state.password_confirmation,
          props.bundle,
          props.payment
        );
        if (result) {
          if (!result.errors) {
            redirecting = true;
            props.push("/home");
          } else {
            setState((orig) => ({
              ...orig,
              error: { ...result.errors, global: result.message },
            }));
          }
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        if (!redirecting) {
          setState((orig) => ({ ...orig, prevent_submit: false }));
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signUp();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const handleBack = (e) => {
    props.push(props.back);
  };

  // Determine which field is required and sort accordingly (required field appears first)
  const accountIdFields = [
    {
      state_value: "phone_number",
      label: "Phone Number",
      required: accountRequirements.required === "phone_number",
      options: {
        autoComplete: "tel_national",
        icon: <PhoneIcon />,
        type: "tel",
      },
    },
    {
      state_value: "email",
      label: "E-Mail",
      required: accountRequirements.required === "email",
      options: {
        autoComplete: "email",
        icon: <EmailIcon />,
        type: "email",
      },
    },
  ].sort(({ required: a }, { required: b }) => (a === b ? 0 : a ? -1 : 1));

  return (
    <React.Fragment>
      {!state.fully_loaded && <LoadingContainer />}
      <div style={{ maxWidth: "270px" }}>
        {props.back && <BackArrow onClick={handleBack} />}
        <H2>Sign Up</H2>
        <Form onSubmit={handleSubmit}>
          <div>
            {accountIdFields.map(
              (
                {
                  state_value,
                  inputComponent: Comp = TextInput,
                  label,
                  required,
                  options,
                },
                i
              ) => (
                <Field key={i}>
                  <Comp
                    {...options}
                    placeholder={formatRequiredLabel(label, required)}
                    name={state_value}
                    id={state_value}
                    onChange={handleChange}
                    value={state[state_value]}
                    error={state.error[state_value]}
                    errorMessage={state.error[state_value]}
                  />
                </Field>
              )
            )}
            <Field>
              <TextInput
                autoComplete="given_name"
                placeholder={formatRequiredLabel("First Name", true)}
                type="text"
                id="first_name"
                name="first_name"
                onChange={handleChange}
                value={state.first_name}
                error={state.error?.first_name}
                errorMessage={state.error?.first_name}
              />
            </Field>
            <Field>
              <TextInput
                autoComplete="family_name"
                placeholder={formatRequiredLabel("Last Name", true)}
                type="text"
                id="last_name"
                name="last_name"
                onChange={handleChange}
                value={state.last_name}
                error={state.error?.last_name}
                errorMessage={state.error?.last_name}
              />
            </Field>
            <Field>
              <Dropdown
                nullable
                nullValue={state.loaded ? "Unspecified" : "Loading..."}
                icon={<LocationIcon />}
                label={formatRequiredLabel("Region", false)}
                id="county"
                name="county"
                onChange={handleChange}
                value={state.county}
                options={state.county_options}
                error={state.error?.county}
                errorMessage={state.error?.county}
              />
            </Field>
            <Field>
              <TextInput
                autoComplete="new_password"
                icon={<LockIcon />}
                placeholder={formatRequiredLabel("Password", true)}
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                value={state.password}
                error={state.error?.password}
                errorMessage={state.error?.password}
              />
            </Field>
            <Field>
              <TextInput
                autoComplete="new_password_confirmation"
                icon={<LockIcon />}
                placeholder={formatRequiredLabel("Confirm Password", true)}
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                onChange={handleChange}
                value={state.password_confirmation}
                error={state.error?.password_confirmation}
                errorMessage={state.error?.password_confirmation}
              />
            </Field>
            <Field>
              <Button disabled={state.prevent_submit} type="submit">
                Register
              </Button>
            </Field>
          </div>
          {state.error && state.error.global && (
            <Error>{state.error.global}</Error>
          )}
        </Form>
      </div>
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  push: (url) => dispatch(push(url)),
}))(SignUp);
