import FS from "./FetchService";

export default class ShopService {
  static async getBundles() {
    return await FS.get("/shop/bundles");
  }

  static async getUpgradeBundles() {
    return await FS.get("/shop/bundles/upgrades");
  }

  static async makePurchase(bundle, payment) {
    return await FS.post("/shop/pay", JSON.stringify({ bundle, payment }), {
      header: (h) => h.append("Content-Type", "application/json"),
    });
  }

  static async purchaseUpgrade(bundle, payment) {
    return await FS.post("/shop/upgrade", JSON.stringify({ bundle, payment }), {
      header: (h) => h.append("Content-Type", "application/json"),
    });
  }

  static async getReceipt(order_id) {
    return await FS.get(`/receipts/${order_id}`);
  }
}
