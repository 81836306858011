import React, { useState, useContext } from "react";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import PhoneIcon from "@material-ui/icons/Phone";
import Button from "components/Input/Button";
import Form from "components/LoginView/Input/Form";

import { AccountContext } from "context/AccountContext";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const Mpesa = (props) => {
  const accountInfo = useContext(AccountContext);
  const [state, setState] = useState({
    phone: accountInfo?.profile?.phone_number || "",
    errors: "",
    preventSubmit: false,
  });

  const [confirm, setConfirm] = useState(false);

  const {
    onChange,
    onSubmit,
    name,
    requireConfirmation,
    confirmationText = "Confirm Purchase",
    checkoutText = "Process Payment",
    disabled: disabledOverride,
  } = props;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
    onChange && onChange({ target });
  };

  const handleConfirm = (e) => {
    setConfirm((orig) => !orig);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (requireConfirmation && !confirm) {
      return false;
    }

    onSubmit &&
      onSubmit({
        target: {
          name: name,
          value: { type: "mpesa", phone: state.phone },
        },
      });

    return false;
  };

  const disabled =
    state.preventSubmit ||
    disabledOverride ||
    (requireConfirmation && !confirm);

  return (
    <Form onSubmit={handleSubmit}>
      <Field>
        <TextInput
          autoComplete="tel_national"
          icon={<PhoneIcon />}
          placeholder="Phone Number"
          type="tel"
          id="phone"
          name="phone"
          onChange={handleChange}
          value={state.phone}
        />
      </Field>
      {requireConfirmation && (
        <FormControlLabel
          style={{ display: "flex", justifyContent: "center" }}
          control={
            <Checkbox
              id="confirmUpgrade"
              color="primary"
              onClick={handleConfirm}
              checked={confirm}
            />
          }
          label={confirmationText}
        />
      )}
      <Field>
        <Button disabled={disabled} type="submit">
          {checkoutText}
        </Button>
      </Field>
    </Form>
  );
};

export default Mpesa;
