import React from "react";

import OrderOverview from "views/ShopView/OrderOverview";
import { H2, H3 } from "components/LoginView/Headings";
import Mpesa from "./PaymentForms/Mpesa";

const SelectPayment = (props) => {
  // const [state, setState] = useState({ paymentType: "mpesa" });

  // const handleChange = ({ target }) => {
  //   const { name, value } = target;
  //   setState((orig) => ({ ...orig, [name]: value }));
  // };

  const { onSubmit, name, bundle, upgrade, checkoutText } = props;

  const handleSubmit = ({ target }) => {
    const { value } = target;

    onSubmit &&
      onSubmit({
        target: { name, value },
      });
  };

  return (
    <React.Fragment>
      {upgrade ? <H2>Manage Plan</H2> : <H2>Pay with MPesa</H2>}
      <OrderOverview {...props} />
      <H3 style={{ textAlign: "left" }}>Billing information</H3>
      <Mpesa
        name="mpesa"
        onSubmit={handleSubmit}
        bundle={bundle}
        requireConfirmation={upgrade}
        confirmationText={'I understand "What Happens Next"'}
        checkoutText={checkoutText}
      />
    </React.Fragment>
  );
};

export default SelectPayment;
