import React from "react";
import styled from "styled-components";

import LoadingSpinner from "./LoadingSpinner";

const LoadingArea = styled.div.attrs(() => ({
  onClick: (e) => {
    e.stopPropagation();
    e.preventDefault();
  },
}))`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ showLoading }) =>
    showLoading ? "rgba(255, 255, 255, 0.8)" : "transparent"};
  border-radius: inherit;

  z-index: 1100;
`;

export default class LoadingContainer extends React.Component {
  showLoadingTimeout = null;

  constructor() {
    super();

    this.state = {
      showLoading: false,
    };
  }

  componentDidMount() {
    this.showLoadingTimeout = setTimeout(
      () => this.setState({ showLoading: true }),
      250
    );
  }

  componentWillUnmount() {
    clearTimeout(this.showLoadingTimeout);
  }

  render() {
    return (
      <LoadingArea showLoading={this.state.showLoading}>
        {this.state.showLoading && <LoadingSpinner />}
      </LoadingArea>
    );
  }
}
