import { H2, H3 } from "components/LoginView/Headings";
import { find, groupBy } from "lodash";
import React, { useState, useEffect } from "react";
import Plan from "components/ShopView/Plan";
import SubscriptionDurationSelector from "components/ShopView/SubscriptionDurationSelector";
import ShopService from "services/ShopService";
import LoadingContainer from "components/Loading/LoadingContainer";

const ExploreBundlesView = (props) => {
  const [state, setState] = useState({
    bundles: null,
    duration: 0,
    preventSubmit: true,
  });

  const { name, onSelect, upgrade } = props;
  const { loaded, bundles, duration } = state;

  const mount = () => {
    loadOptions();
    return () => {};
  };
  useEffect(mount, []);

  const loadOptions = async () => {
    try {
      let bundles, defaultDuration;
      if (upgrade) {
        bundles = await ShopService.getUpgradeBundles();
        defaultDuration = find(bundles, { current: true })?.duration.toString();
      } else {
        bundles = await ShopService.getBundles();
      }

      bundles = groupBy(bundles, "duration");
      if (!defaultDuration) {
        defaultDuration = Object.keys(bundles)[0];
      }

      setState((orig) => ({
        ...orig,
        bundles: bundles,
        duration: defaultDuration,
        preventSubmit: false,
        loaded: true,
      }));
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  return (
    <React.Fragment>
      {loaded ? (
        <React.Fragment>
          <H2>{upgrade ? "Plans" : "Welcome!"}</H2>
          <H3>
            {upgrade
              ? "Select the plan that best suits your needs"
              : "Select a plan to start using Tusitawi"}
          </H3>
          <SubscriptionDurationSelector
            onChange={handleChange}
            options={Object.keys(bundles)}
            bundles={bundles}
            name="duration"
            value={duration}
          />
          {bundles[duration].map((bundle, i) => {
            return (
              <Plan
                key={i}
                bundle={bundle}
                onClick={() =>
                  onSelect &&
                  onSelect({
                    target: {
                      name: name,
                      value: bundle,
                    },
                  })
                }
              />
            );
          })}
        </React.Fragment>
      ) : (
        <LoadingContainer />
      )}
    </React.Fragment>
  );
};

export default ExploreBundlesView;
