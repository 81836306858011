import React, { useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { compose } from "recompose";
import styled from "styled-components";

import Panel from "components/LoginView/Input/Panel";
import _TusitawiLogo from "components/Logo/TusitawiLogo";
import withTheme from "composers/withTheme";

import LoginView from "./LoginView";
import PasswordRecovery from "./PasswordRecoveryView/PasswordRecoveryView";
import PromoView from "./PromoView";
import ShopView from "views/ShopView/ShopView";
import SignUpView from "./SignUpView";

import { AppContext } from "context/AppContext";

const SplitView = compose(withTheme)(styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" &&
    `
  flex-direction: column-reverse;
  `}
  &>* {
    padding: 20px 0;
  }
`);

const Info = compose(withTheme)(styled.div`
  position: relative;
  display: grid;
  align-content: center;
  row-gap: 45px;
  ${({ responsiveSize }) =>
    responsiveSize === "desktop" &&
    `
  width: 470px;
  padding-right: 30px;
  padding-left: 40px;
  `}
  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" &&
    `
  padding-top: 150px;
  padding-right: 30px;
  padding-left: 30px;
  grid-row: 2;
  text-align: center;
  `}
  &>* {
    margin: 0;
  }
`);

const Contents = compose(withTheme)(styled.div`
  min-height: 100vh;
  flex-grow: 1;
  position: relative;
  background: linear-gradient(180deg, #318f4b 0%, #46b866 100%);

  display: grid;
  row-gap: 15px;
  grid-template-rows: 95px auto;
  justify-items: center;
  align-items: center;

  padding: 10px;
  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" &&
    `
    padding-bottom: 132px;
  `}
`);

const TusitawiLogo = compose(withTheme)(styled(_TusitawiLogo)`
  margin-bottom: 0;
  width: 25%;
  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" &&
    `
    height: 65px;
  `}
`);

export const LandingViewBody = compose(withTheme)((props) => {
  const {
    appStyle: { style },
    loaded,
  } = useContext(AppContext);

  const mount = () => {
    window.scroll({ top: 0 });
  };
  useEffect(mount, []);

  return (
    <SplitView>
      <Info>
        <PromoView />
      </Info>
      <Contents>
        <TusitawiLogo rotated />
        <Panel style={{ marginTop: "27px", maxWidth: "500px" }}>
          <Switch>
            <Route path="/forgot-password" component={PasswordRecovery} />
            {(!loaded || style === "subscription") && (
              <Route
                path="/explore"
                render={(routerProps) => (
                  <ShopView
                    {...routerProps}
                    after={SignUpView}
                    checkoutText="Sign Up"
                  />
                )}
              />
            )}
            {(!loaded || style === "trial") && (
              <Route
                path="/signup"
                render={(routerProps) => (
                  <SignUpView {...routerProps} back="/" />
                )}
              />
            )}
            <Route path="/" component={LoginView} />
          </Switch>
        </Panel>
      </Contents>
    </SplitView>
  );
});

export default LandingViewBody;
