import React, { useContext, useState } from "react";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import styled from "styled-components";

import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LogoutModal from "components/AccountView/LogoutModal";
import TusitawiLogo from "components/Logo/TusitawiLogo";
import withTheme from "composers/withTheme";

import AddIcon from "@material-ui/icons/Add";
import BackArrowIcon from "@material-ui/icons/ArrowBack";
import ContactIcon from "@material-ui/icons/ContactSupport";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import FeedbackIcon from "@material-ui/icons/Feedback";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import SchoolIcon from "@material-ui/icons/School";
import GearIcon from "@material-ui/icons/Settings";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { NavigationContext } from "context/NavigationContext";

const Navbar = styled(AppBar)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1400;
`;

const MobileNavigationMenu = styled(List)`
  margin-top: 58px;
  width: 250px;
  overflow-x: hidden;
`;

const MobileLinkWrapper = styled(ListItem)`
  background-color: ${({ current }) => current && "rgba(251, 183, 77, 0.5)"};
`;

const MobileLink = (props) => {
  const { match, icon, label, ...rest } = props;

  return (
    <MobileLinkWrapper button current={!!match ? 1 : 0} {...rest}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MobileLinkWrapper>
  );
};

const DesktopLink = styled(Tab)`
  width: 185px;
  font-weight: 700;
`;

const feedbackLink = "https://forms.gle/cswPorL3ZcS7ezxe7";
const contactLink = "https://forms.gle/9Ldb6Q6kaN3b6gWbA";

const links = [
  { to: "/home", label: "Home", icon: <HomeIcon />, exact: true },
  {
    to: "/account/students",
    label: "Manage Students",
    icon: <SchoolIcon />,
  },
  {
    to: "/account",
    label: "Account Settings",
    icon: <GearIcon />,
  },
];

const DesktopNavbar = (props) => {
  const [feebackMenuState, setFeedbackMenuState] = useState({
    isOpen: false,
    anchor: null,
  });

  const {
    headerHeight,
    onLinkClick,
    onLogoutClick,
    currentLinkIndicies,
    children,
  } = props;

  const toggleFeedbackMenu = ({ currentTarget }, value) => {
    setFeedbackMenuState(({ isOpen }) => {
      const newIsOpen = typeof value === "undefined" ? !isOpen : value;
      return {
        isOpen: newIsOpen,
        anchor: newIsOpen ? currentTarget : null,
      };
    });
  };

  return (
    <Navbar position="static" style={{ height: headerHeight }}>
      <Toolbar variant="dense">
        <Grid container justify="flex-start" alignItems="flex-end" item md={2}>
          <Link to="/">
            <TusitawiLogo />
          </Link>
        </Grid>
        <Grid container justify="flex-end" item md={10}>
          <Toolbar>
            <Tabs
              TabIndicatorProps={{
                style: { backgroundColor: "#fff", height: "4px" },
              }}
              value={(currentLinkIndicies && currentLinkIndicies[0]) || 0}
            >
              {links.map(
                ({ to, label, hide_in_list }, i) =>
                  !hide_in_list && (
                    <DesktopLink
                      style={{ height: headerHeight }}
                      key={i}
                      value={i}
                      label={label}
                      onClick={() => onLinkClick(to)}
                      tabIndex={0}
                    ></DesktopLink>
                  )
              )}
              <DesktopLink
                style={{ height: headerHeight }}
                label="Feedback"
                value={null}
                onClick={(e) => toggleFeedbackMenu(e)}
                tabIndex={0}
              />
              {/* This menu on click is producing a lot of strange console errors. No idea why, honestly. */}
              <Menu
                open={feebackMenuState.isOpen}
                anchorEl={feebackMenuState.anchor}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                onClose={(e) => toggleFeedbackMenu(e, false)}
                getContentAnchorEl={null}
              >
                <MenuItem component="a" href={feedbackLink}>
                  Give Feedback
                </MenuItem>
                <MenuItem component="a" href={contactLink}>
                  Contact Support
                </MenuItem>
              </Menu>
              <DesktopLink
                style={{ height: headerHeight }}
                value={null}
                label="Logout"
                onClick={onLogoutClick}
                tabIndex={0}
              />
            </Tabs>
          </Toolbar>
        </Grid>
      </Toolbar>
      {children}
    </Navbar>
  );
};

const MobileNavbar = (props) => {
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);

  const { backArrowFunc } = useContext(NavigationContext);

  const {
    headerHeight,
    onLinkClick,
    onLogoutClick,
    currentLink,
    children,
  } = props;

  const toggleSidebar = (e, value) => {
    setSidebarExpanded((orig) =>
      typeof value === "undefined" ? !orig : value
    );
  };

  const handleLinkClick = (to) => {
    toggleSidebar(false);
    onLinkClick && onLinkClick(to);
  };

  return (
    <React.Fragment>
      <Navbar position="static" height={headerHeight}>
        <Toolbar>
          {backArrowFunc ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => backArrowFunc()}
            >
              <BackArrowIcon />
            </IconButton>
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={(e) => toggleSidebar()}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Grid container justify="center">
            <Typography variant="h6" align="center">
              {currentLink?.label}
            </Typography>
          </Grid>
        </Toolbar>
      </Navbar>
      <Drawer open={isSidebarExpanded} onClose={(e) => toggleSidebar()}>
        <MobileNavigationMenu value={null}>
          {links.map(({ to, label, icon, children, hide_in_list }, i) => (
            <React.Fragment key={i}>
              {!hide_in_list && (
                <div>
                  <MobileLink
                    current={currentLink?.to === to ? 1 : 0}
                    onClick={(e) => handleLinkClick(to)}
                    icon={icon}
                    label={label}
                  />
                  {children &&
                    children.map(({ to, label, icon }, i) => (
                      <MobileLink
                        key={i}
                        style={{ paddingLeft: "35px" }}
                        current={currentLink && currentLink.to === to ? 1 : 0}
                        onClick={() => handleLinkClick(to)}
                        icon={icon}
                        label={label}
                      />
                    ))}
                  <Divider />
                </div>
              )}
            </React.Fragment>
          ))}
          <MobileLink
            component="a"
            href={feedbackLink}
            icon={<FeedbackIcon />}
            label="Feedback"
          />
          <MobileLink
            component="a"
            href={contactLink}
            icon={<ContactIcon />}
            label="Contact Support"
          />
          <Divider />
          <MobileLink
            onClick={onLogoutClick}
            icon={<LogoutIcon />}
            label="Logout"
          />
        </MobileNavigationMenu>
      </Drawer>
      {children}
    </React.Fragment>
  );
};

const AccountNavbar = (props) => {
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const {
    responsiveSize,
    push,
    location: { pathname },
  } = props;

  const getCurrentLink = (list) => {
    let checkLinks = list;
    if (typeof checkLinks === "undefined") {
      checkLinks = links;
    }

    for (const i in checkLinks) {
      const { to, children, exact } = checkLinks[i];

      let match = false;
      if (exact) {
        match = pathname === to;
      } else {
        match = pathname.startsWith(to);
      }

      if (match) {
        let childLink;
        if (children && (childLink = getCurrentLink(children))) {
          return {
            link: childLink.link,
            indicies: [parseInt(i), ...childLink.indicies],
          };
        }
        return { link: checkLinks[i], indicies: [parseInt(i)] };
      }
    }

    return null;
  };
  const currentLinkInfo = getCurrentLink() || {};
  const {
    link: currentLink = null,
    indicies: currentLinkIndicies = null,
  } = currentLinkInfo;

  const handleLinkClick = (to) => {
    push(to);
  };

  const toggleLogoutModal = (value) => {
    setLogoutModalOpen((orig) =>
      typeof value === "undefined" ? !orig : value
    );
  };

  const ResponsiveNavbar =
    responsiveSize === "desktop" ? DesktopNavbar : MobileNavbar;

  return (
    <ResponsiveNavbar
      {...props}
      onLinkClick={handleLinkClick}
      onLogoutClick={() => toggleLogoutModal(true)}
      currentLink={currentLink}
      currentLinkIndicies={currentLinkIndicies}
    >
      <LogoutModal
        open={isLogoutModalOpen}
        onCancel={() => toggleLogoutModal(false)}
        onLogoutConfirmed={() => push("/logout")}
      />
    </ResponsiveNavbar>
  );
};

export default connect(null, (dispatch) => ({
  push: (url) => dispatch(push(url)),
}))(compose(withTheme)(AccountNavbar));
