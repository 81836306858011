import styled from "styled-components";

const Panel = styled.div`
  position: relative;
  padding: 35px 30px;
  margin: 10px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default Panel;
