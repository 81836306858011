import React, { useContext } from "react";
import StudentListView from "./StudentListView";
import styled from "styled-components";
import { compose } from "recompose";
import withTheme from "composers/withTheme";
import LoadingContainer from "components/Loading/LoadingContainer";
import { Link, Redirect } from "react-router-dom";

import { AccountContext } from "context/AccountContext";
import { AppContext } from "context/AppContext";

const Wrapper = compose(withTheme)(styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  padding-top: ${({ responsiveSize }) =>
    responsiveSize === "desktop" ? "56px" : "36px"};
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: ${({ responsiveSize }) =>
    responsiveSize === "desktop" ? "33px" : "20px"};
  justify-content: center;
  justify-items: center;
`);

const Home = (props) => {
  const { subscription, profile } = useContext(AccountContext);
  const {
    appStyle: { style },
  } = useContext(AppContext);

  if (!profile) {
    // loading
    return <LoadingContainer />;
  }

  return (
    <Wrapper>
      {(() => {
        if (style === "trial") {
          return <StudentListView {...props} />;
        } else if (subscription) {
          switch (subscription.status) {
            case "pending":
              return <Redirect to={`/account/receipt/`} />;
            case "failed":
              return "failed";
            case "expired":
              return "expired";
            case "complete":
              return <StudentListView {...props} />;
            default:
              return null;
          }
        } else {
          // must purchase subscription
          return (
            <React.Fragment>
              <div>Must Purchase New Product</div>
              <Link to="/bundles">Click Here</Link>
            </React.Fragment>
          );
        }
      })()}
    </Wrapper>
  );
};

export default Home;
